import tw, { styled } from "twin.macro"

export const Container = styled.div`
  ${tw`flex flex-col w-full bg-white border border-green rounded-2xl w-full max-w-3xl text-grey-dark`}
`
export const Item = styled.div`
  ${tw`flex flex-col gap-2 p-6 border-b border-green last:border-0`}
`
export const ContentWrapper = styled.div`
  ${tw`flex gap-2 justify-between`}
`
export const FooterWrapper = styled.div`
  ${tw`flex items-center justify-center`}
`

export const Title = styled.div`
  ${tw`text-md font-black`}
  ${({ mobileAlignment }) => mobileAlignment === "left" ? tw`text-left` : tw`text-center`}
`
export const Value = styled.div`
  ${tw`text-md flex-1`}
  ${({ mobileAlignment }) => mobileAlignment === "left" ? tw`text-left` : tw`text-center`}
`
export const Edit = styled.button`
  ${tw`flex pt-1 pl-2`}
`
