import React, { useState, useEffect, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useSanityRequest } from "../../../hooks/useSanity"
import { useApp } from "../../../hooks/useApp"
import { useFunctions } from "../../../hooks/useFunctions"
import { useKlaviyo } from "../../../hooks/useKlaviyo"
import { navigate } from "gatsby-link"

export const withRequests = Component =>
  React.memo(({ name = "Requests", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { member } = useApp()
    const { requests, getRequests, ready, setReady } = useSanityRequest()
    const { requestUpdate, messageSend, loading } = useFunctions()
    const { trackEvent } = useKlaviyo()
    const [active, setActive] = useState(null)

    const title = page?.title
    const content = sanityContent(page?.content)

    useEffect(() => {
      if (!requests?.length) getRequests()
    }, [requests?.length])

    const getRequest = value => {
      const request = requests?.filter(item => item?._id && item?._id === value?.id)?.[0]
      return { ...request, isMentor: request?.mentor?._id === member?._id }
    }

    const handleActive = async value => {
      setActive(value)

      if (value?.mentor && !value?.read) {
        await requestUpdate({
          id: value?.id,
          data: { ...getRequest(value), status: "read" },
        })
        trackEvent("Viewed Booking", { ...getRequest(value), status: "read" })
      }

      navigate(`/account/requests/${value?.id || ""}`)
    }

    const handleStatus = async (status, value) => {
      if (value?.status !== status) {
        await requestUpdate({
          id: value?.id,
          data: { ...getRequest(value), status },
        })
      }
    }

    const handleMessage = async (value, message) => {
      if (message?.length)
        await messageSend({
          id: value?.id,
          data: { member, message },
        })
    }

    // const mapStatusText = (mentor, status) => {
    //   if (status === "new") return mentor ? "New request" : "Unread"
    //   if (status === "read") return mentor ? "Awaiting your response" : "Read"
    //   if (status === "responded") return mentor ? "You have responded" : "Responded"
    //   if (status === "accepted") return mentor ? "You have accepted 🎉" : "Accepted 🎉"
    //   if (status === "rejected") return mentor ? "You have rejected" : "Rejected"
    //   if (status === "declined") return mentor ? "You have declined" : "Declined"
    //   if (status === "cancelled") return mentor ? "Cancelled" : "You have cancelled"
    //   if (status === "completed") return mentor ? "Complete" : "Complete"
    //   return "Archived"
    // }

    const mapStatusText = (mentor, status) => {
      return mentor ? "Received" : "Sent"
    }

    const items = useMemo(
      () =>
        requests?.map(item => ({
          id: item?._id,
          status: item?.status,
          read: item?.status !== "new",
          mentor: item?.mentor?._id == member?._id,
          type: mapStatusText(item?.mentor?._id == member?._id, item?.status),
          user: {
            image: item?.mentee?._id == member?._id ? item?.mentor?.image : item?.mentee?.image,
            name: item?.mentee?._id == member?._id ? item?.mentor?.title : item?.mentee?.title,
          },
          topics: [
            { title: "I would like to connect about...", content: item?.purpose },
            { title: "I need help with...", content: item?.help },
            { title: "I would like to achieve...", content: item?.outcome },
            { title: "I would like to use...", content: [item?.method] },
            { title: "I am located near...", content: item?.where },
            { title: "I can meet on...", content: item?.when },
          ],
          content: "",
          messages: item?.messages?.filter(message => message?.length) || [],
        })),
      [requests, member?._id]
    )

    const activateParams = location?.pathname.split("/account/requests/")[1]?.split("/") || []

    // NOTE: Enable this to activate the first request on page load
    // useEffect(() => {
    //   if (items?.[0]?._id === active?._id && activateParams.length == 0) setActive(items?.[0])
    // }, [requests])

    // Load the active request on page load
    useEffect(() => {
      const activateParams = location?.pathname.split("/account/requests/")[1]?.split("/") || []

      if (activateParams.length > 0) {
        const requestId = activateParams[0]
        const status = activateParams[1]
        const request = items?.filter(item => item.id == requestId)[0]

        // Update the status of the request
        if (request && status) {
          handleStatus(status, request)
        }

        setActive(request)
      }
    }, [items])

    const isDeclined = useMemo(() => activateParams[0] && activateParams[1] === "declined", [activateParams])

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          title={title}
          content={content}
          location={location}
          requests={items}
          active={active}
          isDeclined={isDeclined}
          handleActive={handleActive}
          handleStatus={handleStatus}
          handleMessage={handleMessage}
          loading={loading}
        />
      ),
      [items, active, loading]
    )
  })
