import tw, { styled } from "twin.macro"

import { StyledColumn } from "../../../styled/Column"
import { H1, H2, P } from "../../../styled/Text"
import { StyledContainer } from "../../../styled/Container"

export { StyledRow as Row } from "../../../styled/Row"
export { P } from "../../../styled/Text"

export const Threads = styled(StyledColumn)`
  ${({ active }) => active && tw`hidden lg:block`}
`
export const Details = styled(StyledColumn)`
  ${tw`flex-1 flex flex-col items-center justify-center lg:pl-8`}
  ${({ active }) => active && tw`hidden lg:flex`}
`
export const Container = styled(StyledContainer)`
  ${tw`h-full bg-grey-lightest md:bg-transparent md:h-auto flex flex-col items-center text-center`}
`
export const Title = styled.h1`
  ${tw`text-grey-dark font-extrabold text-3xl leading-none mt-8 w-full mb-12`}
`
export const Description = styled(P)`
  ${tw`text-grey-mid text-lg mx-auto mb-8 w-full`}
`
export const Note = styled(P)`
  ${tw`text-grey-mid text-md mt-2 mb-16 w-full`}
`
export const SubTitleLink = styled.span`
  ${tw`text-pink hover:text-pink-light `}
`
