import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

import { H4, H5, H6, P } from "../../../styled/Text"
import { StyledCard } from "../../../styled/Card"
import { StyledPill } from "../../../styled/Pill"
import { StyledButton } from "../../../styled/Button"
import { StyledInput } from "../../../styled/Forms"

export { StyledRow as Row } from "../../../styled/Row"
export { StyledColumn as Column } from "../../../styled/Column"
export { P } from "../../../styled/Text"

export const Card = styled(StyledCard)`
  ${tw`relative w-full`}
`
export const Image = styled(GatsbyImage)`
  ${tw`block rounded-full mr-6 w-12`}
`
export const ImageColumn = tw.div``
export const DetailsColumn = tw.div`flex-1`
export const Title = tw(H6)`mb-1 mr-8`
export const Excerpt = tw(P)`font-normal truncate mr-8`
export const Notification = styled.div`
  ${tw`bg-pink absolute top-4 right-5 h-4 w-4 rounded-full`}
  ${({ status }) => status == "new" && tw`bg-pink`}
  ${({ status }) => status == "read" && tw`bg-pink-light`}
  ${({ status }) => status == "responded" && tw`bg-yellow-300`}
  ${({ status }) => status == "accepted" && tw`bg-green`}
  ${({ status }) => status == "declined" && tw`bg-grey`}
  ${({ status }) => status == "rejected" && tw`bg-grey`}
  ${({ status }) => status == "cancelled" && tw`bg-grey`}
  ${({ status }) => status == "completed" && tw`bg-white`}
`
export const Name = tw(H4)`mb-0`
export const User = tw.div`flex items-center justify-start mb-12`
export const Topic = tw.div`mb-8`
export const Pills = tw.div`mt-2 -mx-1`
export const Pill = tw(StyledPill)`mx-1 pointer-events-none`
export const Content = tw.div`text-grey-mid`
export const Actions = tw.div`border-t border-grey-lighter mt-12 px-4 pt-6 pb-2`
export const Button = tw(StyledButton)`mr-4`
export const MessageTitle = tw(H4)`text-sm mt-1 mb-0`
export const MessageButton = tw(StyledButton)`mx-4 mt-8`
export const MessageInput = tw(StyledInput)`px-0 pb-2 border-0 border-b border-solid border-grey-light rounded-none shadow-none w-100 text-sm`
export const Messages = tw.div`mt-4`
export const Message = tw.div`py-4 border-b border-grey-lighter`
export const MessageName = tw.span`inline-block w-24 font-bold`
export const MessageText = tw(P)`text-sm`

export const Heading = tw(P)`font-bold`
export const Text = tw(P)`mt-4`
