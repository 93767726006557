import tw, { styled } from "twin.macro"

import { H1, P } from "../../styled/Text"
import { StyledRow } from "../../styled/Row"

export { StyledColumn as Column } from "../../styled/Column"

export const Title = styled(H1)`
  ${tw`mb-0`}
  ${({ size }) => size === "h1" ? tw`text-md md:text-xl lg:text-4xl` : tw`lg:text-xl text-lg`}
`
export const Subtitle = tw(P)`mt-2 text-sm md:text-md lg:text-lg`
export const Count = tw.span`font-normal`
export const Row = tw(StyledRow)`mb-8 md:mb-20`
export const BackLink = tw.button`inline-block lg:hidden absolute w-16 h-16 inset-x-0 left-0 flex justify-center focus:outline-none`
