import React from "react"

import { withRequestsCard } from "./withRequestsCard"
import {
  Row,
  Column,
  Card,
  Image,
  ImageColumn,
  DetailsColumn,
  Title,
  Excerpt,
  Notification,
  Name,
  User,
  Topic,
  Pills,
  Pill,
  Content,
  P,
  Button,
  Actions,
  MessageTitle,
  MessageButton,
  MessageInput,
  Message,
  Messages,
  MessageName,
  MessageText,
  Heading,
  Text,
} from "./RequestsCard.styled"

export const RequestsCard = withRequestsCard(
  ({
    active = false,
    children,
    details,
    message,
    loading,
    text,
    handleText = () => null,
    handleActive = () => null,
    handleStatus = () => null,
    handleMessage = () => null,
  }: Props): JSX.Element =>
    details ? (
      <>
        <Card
          background={active && !children && `grey-pale`}
          hover
          shadow={!active && !children ? `medium` : children ? `small` : null}
          onClick={!children ? () => handleActive(message) : null}
          size={children ? `xlarge` : `medium`}
        >
          <User>
            <Column position={`left`}>{message?.user?.image && <Image image={message?.user?.image} />}</Column>
            <Column position={`left`}>
              <Name size={`large`}>{message?.user?.name}</Name>
            </Column>
          </User>
          <div>
            {message?.topics?.map(({ title, content }, i) =>
              content?.length > 0 || content?.[0]?.length > 0 ? (
                <Topic key={i}>
                  <Heading>{title}</Heading>
                  {Array.isArray(content) ? (
                    <Pills>
                      {content?.map((content, i) =>
                        content?.includes("Other: ") ? (
                          <Text>{content}</Text>
                        ) : (
                          <Pill key={i} theme={`tertiary`} colour={`outline`} size={`large`}>
                            {content}
                          </Pill>
                        )
                      )}
                    </Pills>
                  ) : (
                    <Text>{content}</Text>
                  )}
                </Topic>
              ) : null
            )}
          </div>

          <Content dangerouslySetInnerHTML={{ __html: message?.content }} />

        {/* Comment out action buttons while feature is not available */}
        
          {/* {["new", "read", "responded", "accepted"].includes(message?.status) && (
            <Actions>
              {message?.mentor && ["new", "read", "responded"].includes(message?.status) && (
                <Button theme={"secondary"} colour={"dark"} size={"medium"} onClick={() => handleStatus("accepted", message)}>
                  Accept request
                </Button>
              )}
              {message?.mentor && ["new", "read", "responded"].includes(message?.status) && (
                <>
                  <Button theme={"minimal"} colour={"dark"} size={"medium"} onClick={() => handleStatus("declined", message)}>
                    Decline request
                  </Button>

                  <Text>Can't fulfil this booking? Decline and alert us.</Text>
                </>
              )}
              {!message?.mentor && ["new", "read", "responded", "accepted"].includes(message?.status) && (
                <Button theme={"minimal"} colour={"dark"} size={"medium"} onClick={() => handleStatus("cancelled", message)}>
                  Cancel request
                </Button>
              )}
              {["accepted"].includes(message?.status) && (
                <Button theme={"minimal"} colour={"dark"} size={"medium"} onClick={() => handleStatus("completed", message)}>
                  Mark as completed
                </Button>
              )}
            </Actions>
          )} */}
        </Card>
        {/* <Card shadow={`medium`} size={`medium`}>
          <MessageTitle>Messages history</MessageTitle>

          {message?.messages?.length > 0 && (
            <Messages>
              {message?.messages?.map(message => (
                <Message>
                  <MessageText>
                    <MessageName>{message?.split(":")?.[0]}</MessageName>
                    {message?.split(":")?.[1]}
                  </MessageText>
                </Message>
              ))}
            </Messages>
          )}

          <MessageInput
            type={"text"}
            name={"message"}
            placeholder={"Enter your message..."}
            value={text}
            onChange={value => handleText(value?.target?.value)}
          />

          <MessageButton
            theme={"secondary"}
            colour={"dark"}
            size={"medium"}
            onClick={() => {
              handleMessage(message, text)
              handleText("")
            }}
            loading={loading}
          >
            Send message
          </MessageButton>
        </Card> */}
      </>
    ) : (
      <Card
        background={active && !children && `grey-pale`}
        hover
        shadow={!active && !children ? `medium` : children ? `small` : null}
        onClick={!children ? () => handleActive(message) : null}
        size={children ? `xlarge` : `medium`}
      >
        <Row>
          <ImageColumn>{message?.user?.image && <Image image={message?.user?.image} />}</ImageColumn>
          <DetailsColumn>
            <Title>{message?.user?.name}</Title>
            <Excerpt>{message?.type}</Excerpt>
          </DetailsColumn>
        </Row>
        {message?.status && message?.status !== "completed" ? <Notification status={message?.status} /> : null}
      </Card>
    )
)

export interface Props {
  active?: boolean
  children?: any
  details?: boolean
  message?: {
    content: string
    type?: string
    topics: Array<{
      title: string
      content: Array<any>
    }>
    messages: Array<string>
    user: {
      name: string
      image: any
    }
    read?: boolean
    mentor?: boolean
    status?: string
  }
  loading?: boolean
  text?: any
  handleText?: (message) => void
  handleActive?: (message) => void
  handleStatus?: (status: string, request: any) => void
  handleMessage?: (request: any, message: string) => void
}
