import React from "react"
import { Link } from "gatsby"

import { Icon } from "../Icon/Icon"
import { BackLink, Column, Count, Row, Subtitle, Title } from "./Intro.styled"

export const Intro = ({ backLink, backClick, count = 0, title, subtitle, titleSize = "h1", alignment = "center" }: Props): JSX.Element => (
  <Row center wrap>
    {backLink ? (
      <Column position={`center`} width={`w-1/4`}>
        <BackLink as={backClick ? "a" : Link} onClick={backClick} to={backClick ? backLink : null}>
          <Icon name={"chevronLeft"} size={"s"} colour={"tertiary"} />
        </BackLink>
      </Column>
    ) : null}
    <Column position={alignment} width={backLink ? `w-1/2` : `w-full`}>
      <Title size={titleSize}>
        {title} <Count hidden={!count}>{`(${count})`}</Count>
      </Title>
    </Column>
    {backLink ? (
      <Column position={`center`} width={`w-1/4`}>
        &nbsp;
      </Column>
    ) : null}
    {subtitle ? (
      <Column position={alignment} width={`w-full`}>
        <Subtitle>{subtitle}</Subtitle>
      </Column>
    ) : null}
  </Row>
)

export interface Props {
  title: string
  subtitle?: string
  count?: number
  backLink?: string
  backClick?: () => void
  titleSize?: "h1" | "h3"
  alignment: string
}
