import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../hoc/withAuthentication"
import { withMembership } from "../../hoc/withMember"
import { Requests as Page } from "../../components/Customer/Requests/Requests"

export const query = graphql`
  query {
    page: sanityPageAccountRequests {
      title
      # content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default withAuthentication(withMembership(({ data, ...props }) => <Page {...props} {...data} />))
