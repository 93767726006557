import React, { useEffect, useState } from "react"

import { withRequests } from "./withRequests"
import { Intro } from "../../Intro/Intro"
import { RequestsCard } from "./RequestsCard"
import { Container, Description, Details, Note, P, Row, SubTitleLink, Threads, Title } from "./Requests.styled"

import { StyledContainer } from "../../../styled/Container"
import { Link } from "gatsby"
import { TableConfirm } from "../../TableConfirm/TableConfirm"
import { useApp } from "../../../hooks/useApp"

export const Requests = withRequests(
  ({ requests = [], active, loading, isDeclined, handleActive, handleStatus, handleMessage }: Props): JSX.Element => {
    const { member } = useApp()
    const [remainingSessions, setRemainingSessions] = useState(0)
    const [bookingRemaining, setBookingRemaining] = useState(0)
    const [totalHours, setTotalHours] = useState(0)

    useEffect(() => {
      if (member?.membership?.daysAvailable && member?.membership?.daysAvailable.length > 0) {
        setRemainingSessions(member?.membership?.remaining || 0)
        setBookingRemaining(member?.membership?.booking || 0)
        const plan = member?.membership?.plan?.title || ""
        const totalHours = plan.includes("VIP") ? bookingRemaining * 2 : bookingRemaining
        setTotalHours(totalHours)
      }
    }, [member?.membership?.daysAvailable])

    return (
    <StyledContainer spacing={`lg`} topSpacing={`lg`} width={`xl`}>
      {isDeclined ? (
        <Container width="lg">
          <Title>Your booking has been declined</Title>
          <Description>
            Thanks for letting us know! We have now replenished your Mentor Hour, which means you're still available to be booked by someone else.
            Don't forget, you can always{" "}
            <SubTitleLink as={Link} to="/account/profile">
              reduce your hours to '0'
            </SubTitleLink>{" "}
            when life gets busy.
          </Description>
          <Description>
            Please take a moment to review your{" "}
            <SubTitleLink as={Link} to="/account/profile">
              Member Profile
            </SubTitleLink>{" "}
            to ensure Mentor Hour bookings are relevant to your experience.
          </Description>
        </Container>
      ) : (
        <>
          <Intro
              title={`Bookings`}
              subtitle={`Here's an overview of your Mentor Hour bookings.`} alignment={"center"}
              backClick={active ? () => handleActive(null) : undefined}
              backLink={active ? "/account/dashboard" : undefined}
          />
          <Container width="lg">
            <TableConfirm
              mobileAlignment="center"
              items={[
                {
                  title: "Mentor Sessions Remaining",
                  value: `${remainingSessions} left`,
                },
                {
                  title: "Mentee Sessions Remaining",
                  value: `${bookingRemaining} left`,
                },
                ...(totalHours > 5
                  ? [{
                      title: "Total number of hours given to help others",
                      value: totalHours,
                    }]
                  : [])
              ]}
            />
            <Note>
              *Please note all hours reset on 1st of the month.
            </Note>
          </Container>
          <Intro
            title={`Mentor Hour Bookings`}
            subtitle="Please check your emails to reply, confirm or cancel Mentor Hour bookings"
            count={requests.length}
            titleSize="h3"
            alignment="left"
          />
          <Row center={!active} wrap>
            <Threads active={active} width={`lg:w-1/3`}>
              {requests.map((message, i) => (
                <RequestsCard active={active && active.id === message.id} handleActive={handleActive} key={i} message={message} />
              ))}
            </Threads>
            <Details active={!active} width={`lg:w-2/3`}>
              {active ? (
                <RequestsCard details message={active} handleStatus={handleStatus} handleMessage={handleMessage} loading={loading} />
              ) : (
                <P>No message selected</P>
              )}
            </Details>
          </Row>
        </>
      )}
    </StyledContainer>
  )}
)

export interface Props {
  backLink: string
  requests: Array<any>
  active?: any
  loading?: boolean
  /**
   * `true` when user navigates to `/account/requests/{request_id}/declined`.
   * This will show a full page message that the request has been declined
   * instead of a list of requests.
   */
  isDeclined?: boolean
  handleActive?: (request: any) => void
  handleStatus?: (status: string, request: any) => void
  handleMessage?: (request: any, message: string) => void
}
