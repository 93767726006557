import tw, { styled } from "twin.macro"

const styles = {
  size: {
    small: tw`py-1 px-4 text-xxs`,
    large: tw`py-1 px-5 text-xs`,
  },
  colour: {
    primary: {
      light: tw``,
      dark: tw`text-white bg-pink border-pink hover:bg-transparent hover:bg-pink-light hover:border-pink-light`,
      outline: tw`hover:text-white text-pink hover:bg-pink border-pink`,
    },
    secondary: {
      light: tw``,
      dark: tw`text-white bg-green border-green hover:bg-transparent hover:bg-green-light hover:border-green-light`,
      outline: tw`hover:text-white text-green hover:bg-green border-green`,
    },
    tertiary: {
      light: tw``,
      dark: tw`text-grey-mid bg-white border-white hover:bg-grey-lightest hover:border-grey-lightest`,
      outline: tw`text-grey-mid border-grey-mid bg-transparent hover:text-white hover:bg-grey-mid`,
    },
  },
}

export const StyledPill = styled.button<{
  size: string
  theme: string
  colour: string
}>`
  ${tw`select-none rounded-full font-medium tracking-wide outline-none relative overflow-hidden border`}
  ${({ size }) => (size ? styles.size[size] : null)}
  ${({ theme, colour }) => (theme ? styles.colour[theme][colour] : null)}
`
