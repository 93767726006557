import React, { ReactNode } from "react"
import { Container, ContentWrapper, Edit, FooterWrapper, Item, Title, Value } from "./TableConfirm.styled"
import { Icon } from "../Icon/Icon"

type Props = {
  items: {
    title: string;
    value: ReactNode;
    footer?: ReactNode;
    onEdit?: () => void
  }[]
  mobileAlignment?: "left" | "center"
}

export const TableConfirm = ({ items, mobileAlignment = "left" }: Props) => {
  return (
    <>
      <Container>
        {items.map(item => (
          <Item key={item.title}>
            <ContentWrapper>
              <Title mobileAlignment={mobileAlignment}>{item.title}:</Title>
              <Value className="hidden md:flex">{item.value}</Value>
              {item.onEdit && (
                <Edit onClick={item.onEdit}>
                  <Icon name={"edit"} size={"ml"} colour="grey" />
                </Edit>
              )}
            </ContentWrapper>
            <Value className="md:hidden" mobileAlignment={mobileAlignment}>{item.value}</Value>
            {item.footer && <FooterWrapper>{item.footer}</FooterWrapper>}
          </Item>
        ))}
      </Container>
    </>
  )
}
